import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, skipWhile } from 'rxjs';
import {
  AccessibleTenants,
  AddResourceAccess,
  AppState,
  CatalogCode,
  CreateInvitation,
  CreateTenant,
  EntitlementData,
  FavoriteApps,
  JoinTenant,
  RemoveAccess,
  ServiceVisibility,
  UpdateTenant,
  UploadLogoPreOperation,
  UserFeedback,
  UserRole,
  Entitlement,
  PageRequest,
  ApplicationCardAction,
  ApplicationCard,
} from '../lemans-app.model';
import {
  addAccess,
  applyCustomProvisioningEntitlement,
  applyRegularEntitlement,
  approveAccess,
  approveUser,
  createInvitations,
  createSelectedFakeEntitlements,
  createTenant,
  deleteInvitation,
  dismissUser,
  generateInviteCode,
  getAllTenantResourceRoles,
  getServicesVisibility,
  getTenantUtilityTokens,
  getUserPreferences,
  getUserPreferencesFailed,
  getUserResources,
  getSuggestedTenantByCode,
  getUserRolesForTenant,
  initializeCustomProvisioning,
  joinTenant,
  leaveCustomProvisioning,
  removeAccess,
  resendInvitation,
  setEditUserDetails,
  setFavoriteApps,
  setTenantCreated,
  setUserId,
  shareFeedback,
  switchTenant,
  updateServicesVisibility,
  updateTenant,
  uploadOrgLogo,
  deallocateEntitlement,
  updatePreferredLanguage,
  acceptInvitation,
  getTenantUsers,
  cardClicked,
  removeMulitpleAccess,
} from './user-data.action';
import {
  accessibleTenants,
  currentTenant,
  currentTenantContext,
  currentTenantId,
  effectiveRoles,
  hasBusinessPartnerId,
  joinOrgSuccess,
  suggestedTenant,
  newTenantCreated,
  notificationCounts,
  notificationDeleted,
  notificationMarkedRead,
  notifications,
  selectCustomProvisioningState,
  selectEditUser,
  selectExitFromProvisioning,
  selectFavoriteApps,
  selectPendingServices,
  selectPreferences,
  selectResourceRoles,
  selectResources,
  selectServices,
  selectServicesVisibility,
  selectTenantUtilityTokenBalance,
  selectTenantUtilityTokenEntitlements,
  selectUserData,
  selectUserID,
  selectUserName,
  selectUserResourceRoles,
  updatedTenantName,
  preferredLanguage,
  selectTenantUsers,
  noSuggestedTenant,
} from './user-data.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserDataFacade {
  userData$ = this.store$.select(selectUserData);
  hasBusinessPartnerId$ = this.store$.select(hasBusinessPartnerId);
  userID$ = this.store$.select(selectUserID);
  userName$ = this.store$.select(selectUserName);
  joinOrgSuccess$ = this.store$.select(joinOrgSuccess);
  suggestedTenant$ = this.store$.select(suggestedTenant).pipe(filter((value) => value !== undefined));
  noSuggestedTenant$ = this.store$.select(noSuggestedTenant);
  currentTenantId$ = this.store$.select(currentTenantId).pipe(skipWhile((tenantId) => tenantId === undefined));
  newTenantCreated$ = this.store$
    .select(newTenantCreated)
    .pipe(filter((newTenantCreated) => newTenantCreated === true));
  currentTenantContext$ = this.store$.select(currentTenantContext);
  currentTenant$ = this.store$.select(currentTenant);
  allAccessibleTenants$ = this.store$.select(accessibleTenants);
  accessibleTenants$ = this.allAccessibleTenants$.pipe(
    map((tenants: AccessibleTenants[]) => tenants?.filter((tenant) => tenant.name !== 'Personal Tenant')),
  );
  hasOnlyPersonalTenant$ = this.accessibleTenants$.pipe(map((tenants: AccessibleTenants[]) => tenants?.length === 0));
  effectiveRoles$ = this.store$.select(effectiveRoles);
  notifications$ = this.store$.select(notifications);
  notificationCounts$ = this.store$.select(notificationCounts);
  notificationDeleted$ = this.store$.select(notificationDeleted);
  notificationMarkedRead$ = this.store$.select(notificationMarkedRead);
  resourceRoles$ = this.store$.select(selectResourceRoles);
  userResourceRoles$ = this.store$
    .select(selectUserResourceRoles)
    .pipe(filter((userResourceRoles) => userResourceRoles !== undefined));
  allResourceRoles$ = this.resourceRoles$.pipe(filter((allResourceRoles) => allResourceRoles !== undefined));
  getResources$ = this.store$.select(selectResources);
  tenantUsers$ = this.store$.select(selectTenantUsers);
  getSelectedEditUser$ = this.store$.select(selectEditUser);
  updatedTenantName$ = this.store$.select(updatedTenantName).pipe(filter((name) => name !== undefined));
  servicesVisibility$ = this.store$
    .select(selectServicesVisibility)
    .pipe(skipWhile((servicesVisibility) => servicesVisibility.length === 0));
  services$ = this.store$.select(selectServices);
  tokenEntitlements$ = this.store$
    .select(selectTenantUtilityTokenEntitlements)
    .pipe(skipWhile((tokenEntitlements) => tokenEntitlements === undefined));
  tokenBalance$ = this.store$.select(selectTenantUtilityTokenBalance);
  pendingServices$ = this.store$.select(selectPendingServices);
  currentCustomProvisioningData$ = this.store$.select(selectCustomProvisioningState);
  currentPreferences$ = this.store$.select(selectPreferences);
  exitFromProvisioning$ = this.store$.select(selectExitFromProvisioning);
  favoriteApps$ = this.store$.select(selectFavoriteApps);
  preferredLanguage$ = this.store$.select(preferredLanguage).pipe(filter((lang) => lang !== undefined));

  constructor(private store$: Store<AppState>) {}

  setUserID(userID: string): void {
    this.store$.dispatch(setUserId({ payload: userID.replace(/-/g, '') }));
  }

  getUserPreferences(isInitialState: boolean, isNewUser: boolean): void {
    this.store$.dispatch(getUserPreferences({ isInitialState: isInitialState, isNewUser: isNewUser }));
  }

  getAllTenantResourceRoles(tenantId: string): void {
    this.store$.dispatch(getAllTenantResourceRoles({ payload: tenantId }));
  }

  getUserRolesForTenant(tenantId: string, userId: string): void {
    this.store$.dispatch(getUserRolesForTenant({ tenantId: tenantId, userId: userId }));
  }

  languageChange(languageCode: string): void {
    this.store$.dispatch(updatePreferredLanguage({ preferredLanguage: languageCode }));
  }

  tenantChange(tenantId: string): void {
    this.store$.dispatch(switchTenant({ value: tenantId }));
  }

  createTenant(
    tenant: CreateTenant,
    blobImage: Blob,
    servicesVisibilityRequestBody: ServiceVisibility[],
    idempotencyKey: string,
  ): void {
    this.store$.dispatch(
      createTenant({
        blobImage: blobImage,
        payload: tenant,
        servicesVisibilityRequestBody: servicesVisibilityRequestBody,
        idempotencyKey,
      }),
    );
  }

  resetTenantCreated(): void {
    this.store$.dispatch(setTenantCreated({ value: false }));
  }

  updateTenant(tenant: UpdateTenant, blobImage?: Blob): void {
    this.store$.dispatch(updateTenant({ blobImage: blobImage, payload: tenant }));
  }

  uploadOrgLogo(tenantId: string, blobImage: Blob, preOperation: UploadLogoPreOperation): void {
    this.store$.dispatch(uploadOrgLogo({ blobImage: blobImage, preOperation: preOperation, tenantId: tenantId }));
  }

  generateInviteCode(tenantId: string): void {
    this.store$.dispatch(generateInviteCode({ tenantId: tenantId }));
  }

  joinTenant(tenant: JoinTenant, isRedirectToDashboard?: boolean): void {
    this.store$.dispatch(joinTenant({ payload: tenant, isRedirectToDashboard }));
  }

  createInvitations(invitations: CreateInvitation[]): void {
    this.store$.dispatch(createInvitations({ payload: invitations }));
  }

  resendInvitation(tenantId: string, invitationId: string): void {
    this.store$.dispatch(resendInvitation({ invitationId: invitationId, tenantId: tenantId }));
  }

  deleteInvitation(tenantId: string, invitationId: string): void {
    this.store$.dispatch(deleteInvitation({ invitationId: invitationId, tenantId: tenantId }));
  }

  shareFeedback(feedback: UserFeedback): void {
    this.store$.dispatch(shareFeedback({ payload: feedback }));
  }

  getUserResources(tenantId: string): void {
    this.store$.dispatch(getUserResources({ value: tenantId }));
  }
  getSuggestedTenantByCode(inviteCode: string): void {
    this.store$.dispatch(getSuggestedTenantByCode({ inviteCode: inviteCode }));
  }

  createSelectedFakeEntitlements(payload: CatalogCode[], tenantId: string): void {
    this.store$.dispatch(createSelectedFakeEntitlements({ payload: payload, tenantId: tenantId }));
  }

  applyCustomProvisioningEntitlement(
    userId: string,
    tenantId: string,
    entitlementData: EntitlementData,
    quantity: number,
    domain: string,
  ): void {
    const params = {
      userId: userId,
      tenantId: tenantId,
      entitlementData: entitlementData,
      quantity: quantity,
      domain: domain,
    };
    this.store$.dispatch(applyCustomProvisioningEntitlement(params));
  }

  applyRegularEntitlement(
    userId: string,
    tenantId: string,
    entitlementData: EntitlementData,
    quantity: number,
    domain: string,
    organizationName?: string | undefined,
  ): void {
    const regularParams = {
      userId: userId,
      tenantId: tenantId,
      entitlementData: entitlementData,
      quantity: quantity,
      domain: domain,
      organizationName: organizationName,
    };
    this.store$.dispatch(applyRegularEntitlement(regularParams));
  }

  deallocateEntitlement(
    tenantId: string,
    entitlement: Entitlement,
    quantity: number | 'Full',
    organizationName?: string,
  ): void {
    const payload = {
      tenantId: tenantId,
      entitlement: entitlement,
      quantity: quantity,
      organizationName: organizationName,
    };
    this.store$.dispatch(deallocateEntitlement(payload));
  }

  addAccess(payload: AddResourceAccess[], tenantId: string, flow?: 'add' | 'edit' | 'approve'): void {
    this.store$.dispatch(addAccess({ flow: flow, payload: payload, tenantId: tenantId }));
  }
  approveAccess(payload: AddResourceAccess, userData: any): void {
    this.store$.dispatch(approveAccess({ payload, userData }));
  }
  approveUser(payload: AddResourceAccess, id: string): void {
    this.store$.dispatch(approveUser({ id: id, payload: payload }));
  }
  dismissUser(userId: string, tenantId: string): void {
    this.store$.dispatch(dismissUser({ tenantId: tenantId, value: userId }));
  }
  setEditUser(payload: Partial<UserRole>): void {
    this.store$.dispatch(setEditUserDetails({ payload }));
  }

  removeAccess(payload: RemoveAccess): void {
    this.store$.dispatch(removeAccess({ payload: payload }));
  }

  removeMulitpleAccess(payload: RemoveAccess[]): void {
    this.store$.dispatch(removeMulitpleAccess({ payload: payload }));
  }

  acceptInvitation(invitationId: string): void {
    this.store$.dispatch(acceptInvitation({ invitationId: invitationId }));
  }

  getUserPreferencesFailed(httpError: any): void {
    this.store$.dispatch(getUserPreferencesFailed({ value: httpError }));
  }

  getServicesVisibility(tenantId: string): void {
    this.store$.dispatch(getServicesVisibility({ value: tenantId }));
  }

  updateTenantVisibility(tenantId: string, servicesVisibility: ServiceVisibility[]): void {
    this.store$.dispatch(updateServicesVisibility({ payload: servicesVisibility, tenantId: tenantId }));
  }

  getTenantUtilityTokens(tenantId: string): void {
    this.store$.dispatch(getTenantUtilityTokens({ value: tenantId }));
  }
  initializeCustomProvisioning(customProvisioningData: EntitlementData): void {
    this.store$.dispatch(
      initializeCustomProvisioning({
        payload: {
          app: customProvisioningData.app,
          continue: customProvisioningData?.continue,
          tenantId: customProvisioningData.tenantId,
          entitlement: customProvisioningData.entitlement,
          serviceId: customProvisioningData.serviceId,
          userId: customProvisioningData.userId,
        },
      }),
    );
  }

  resetCustomProvisioningData(): void {
    this.store$.dispatch(leaveCustomProvisioning());
  }

  setFavoriteApps(favoriteApps: FavoriteApps): void {
    this.store$.dispatch(setFavoriteApps({ favoriteApps }));
  }

  getTenantUsers(payload: PageRequest): void {
    this.store$.dispatch(getTenantUsers({ payload }));
  }

  cardClicked(action: ApplicationCardAction, card: ApplicationCard): void {
    this.store$.dispatch(cardClicked({ action: action, card: card }));
  }
}
