<ng-container *ngIf="isAuthenticated$ | async; else userNotLoggedIn"></ng-container>

<ng-template #userNotLoggedIn>
  <ng-container *ngIf="getFeatureFlags$ | async">
    <ng-container *ngIf="!mergeMyRockwellSSOlogin">
      <lib-landing
        [displaySSOSignInButton]="enableSSOLogin || false"
        (signInClick)="login()"
        (ssoSignInClick)="loginSSO()"
        [landingImageUrl]="landingImageURL"
      >
      </lib-landing>
    </ng-container>
    <ng-container *ngIf="mergeMyRockwellSSOlogin">
      <lib-landing (signInClick)="loginSSO()" [landingImageUrl]="landingImageURL"></lib-landing>
    </ng-container>
  </ng-container>
</ng-template>
