/** TO DO: fix the rule */
/* eslint-disable @ngrx/on-function-explicit-return-type */
import { Action, createReducer, on } from '@ngrx/store';
import {
  createTenantSuccess,
  customProvisioningCompleted,
  joinTenantSuccess,
  leaveCustomProvisioning,
  setAllTenantResourceRolesSuccess,
  setTenantDetails,
  setTenantId,
  setEditUserDetails,
  setFavoriteAppsSuccess,
  setServicesVisibilitySuccess,
  setTenantCreated,
  setTenantEffectiveRoles,
  setTenantServices,
  setTenantUtilityTokens,
  setUserId,
  getUserPreferencesSuccess,
  setUserResourcesSuccess,
  setSuggestedTenantByCodeSuccess,
  setUserRolesForTenantSuccess,
  updateTenantNameSuccess,
  userData,
  waitForCustomProvisioningComplete,
  setCurrentLanguage,
  setTenantUsers,
  resetTenant,
  setNoSuggestedTenantByCodeFound,
  setTenantLogo,
} from './user-data.action';

import { UserPreferences, UserRole, ITenantDetail } from '@ra-state';

export const userDataInitialState: UserPreferences = {
  businessPartnerId: '',
  company: '',
  email: '',
  eulaAcceptanceRequired: false,
  eulaContentUrl: '',
  eulaVersion: '',
  firstName: '',
  lastName: '',
  location: '',
  name: '',
  notificationCounts: { totalNotifications: 0, unreadNotifications: 0 },
  notifications: [],
  tenants: [],
  newTenantCreated: false,
  services: [],
  servicesVisibility: [],
  preferences: {
    disableTokenNotification: false,
    language: 'en-US',
  },
};

const psetUserData = createReducer(
  userDataInitialState,
  on(userData, (state: UserPreferences, { value }) => ({
    ...state,
    businessPartnerId: value.businessPartnerId,
    company: value.company,
    email: value.email,
    eulaAcceptanceRequired: value.eulaAcceptanceRequired,
    eulaContentUrl: value.eulaContentUrl,
    eulaVersion: value.eulaVersion,
    firstName: value.firstName,
    lastName: value.lastName,
    location: value.location,
    name: value.name,
    tenants: value.tenants,
  })),
  on(setUserId, (state: UserPreferences, { payload }) => ({
    ...state,
    userId: payload,
  })),
  on(setCurrentLanguage, (state: UserPreferences, { preferredLanguage }) => ({
    ...state,
    preferences: { ...state.preferences, language: preferredLanguage },
  })),
  on(setTenantId, (state: UserPreferences, { value }) => ({
    ...state,
    currentTenantId: value,
  })),
  on(createTenantSuccess, (state: UserPreferences) => ({
    ...state,
    newTenantCreated: true,
  })),
  on(setTenantCreated, (state: UserPreferences, { value }) => ({
    ...state,
    newTenantCreated: value,
  })),
  on(setTenantDetails, (state: UserPreferences, { payload }) => ({
    ...state,
    currentTenant: payload,
  })),
  on(resetTenant, (state: UserPreferences) => ({
    ...state,
    currentTenant: undefined,
  })),
  on(getUserPreferencesSuccess, (state: UserPreferences, { value }) => ({
    ...state,
    accessibleTenants: value.accessibleTenants,
    businessPartnerId: value.businessPartnerId,
    company: value.company,
    email: value.email,
    eulaAcceptanceRequired: value.eulaAcceptanceRequired,
    eulaContentUrl: value.eulaContentUrl,
    eulaVersion: value.eulaVersion,
    firstName: value.firstName,
    lastName: value.lastName,
    location: value.location,
    name: value.name,
    preferences: {
      ...state.preferences,
      lastAccessedTenantId: value.preferences?.lastAccessedTenantId,
      disableTokenNotification:
        value.preferences?.disableTokenNotification || state.preferences?.disableTokenNotification,
      favoriteApps: value.preferences?.favoriteApps,
      language: value.preferences?.language,
    },
  })),
  on(setTenantEffectiveRoles, (state: UserPreferences, { effectiveRoles, tenantRole }) => ({
    ...state,
    effectiveRoles: effectiveRoles,
    currentTenantContext: {
      id: tenantRole.tenantId,
      name: tenantRole.resourceName,
      role: tenantRole?.role,
    },
  })),
  on(setAllTenantResourceRolesSuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    resourceRoles: payload,
  })),
  on(setUserRolesForTenantSuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    userResourceRoles: payload,
  })),
  on(setUserResourcesSuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    resources: payload,
  })),
  on(
    setTenantUsers,
    (state: UserPreferences, { selectedTenantUsers }): UserPreferences => ({
      ...state,
      selectedTenantUsers,
    }),
  ),
  on(setSuggestedTenantByCodeSuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    suggestedTenant: payload,
  })),
  on(setNoSuggestedTenantByCodeFound, (state: UserPreferences, { payload }) => ({
    ...state,
    noSuggestedTenant: payload,
  })),
  on(setEditUserDetails, (state: UserPreferences, { payload }) => ({
    ...state,
    editUser: payload as UserRole,
  })),
  on(updateTenantNameSuccess, (state: UserPreferences, { value }) => ({
    ...state,
    updatedTenantName: value,
  })),
  on(joinTenantSuccess, (state: UserPreferences) => ({
    ...state,
    showJoinSuccessInlineMessage: true,
  })),
  on(setServicesVisibilitySuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    servicesVisibility: payload,
  })),
  on(setTenantServices, (state: UserPreferences, { payload }) => ({
    ...state,
    services: payload,
  })),
  on(setTenantUtilityTokens, (state: UserPreferences, { payload }) => ({
    ...state,
    tenantUtilityTokens: payload,
  })),
  on(waitForCustomProvisioningComplete, (state: UserPreferences, { payload }) => ({
    ...state,
    currentCustomProvisioningData: payload,
    exitFromProvisioning: false,
  })),
  on(customProvisioningCompleted, leaveCustomProvisioning, (state: UserPreferences) => ({
    ...state,
    currentCustomProvisioningData: undefined,
    exitFromProvisioning: true,
  })),
  on(setFavoriteAppsSuccess, (state: UserPreferences, { favoriteApps }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      favoriteApps: favoriteApps,
    },
  })),
  on(setTenantLogo, (state: UserPreferences, { value }) => ({
    ...state,
    currentTenant: { ...state.currentTenant, logoImage: value } as ITenantDetail,
  })),
);

export function setUserData(state: UserPreferences, action: Action): UserPreferences {
  return psetUserData(state, action);
}
