import { createSelector } from '@ngrx/store';
import { AppState, UserPreferences } from '../lemans-app.model';

export const selectUserDataFeature = (state: AppState): any => state.userData;
export const selectUserData = createSelector(selectUserDataFeature, (state: UserPreferences) => state);
export const selectUserName = createSelector(selectUserDataFeature, (state: UserPreferences) => state.name);
export const selectResourceRoles = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.resourceRoles,
);
export const selectResources = createSelector(selectUserDataFeature, (state: UserPreferences) => state.resources);
export const selectTenantUsers = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.selectedTenantUsers,
);
export const selectUserResourceRoles = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.userResourceRoles,
);
export const selectEditUser = createSelector(selectUserDataFeature, (state: UserPreferences) => state.editUser);
export const selectUserID = createSelector(selectUserDataFeature, (state: UserPreferences) => state.userId);
// TO DO: add prefix to fix the rule
/* eslint-disable @ngrx/prefix-selectors-with-select */
export const hasBusinessPartnerId = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.businessPartnerId,
);
export const updatedTenantName = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.updatedTenantName,
);
export const currentTenantId = createSelector(selectUserDataFeature, (state: UserPreferences) => state.currentTenantId);
export const preferredLanguage = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.preferences?.language,
);
export const newTenantCreated = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.newTenantCreated,
);
export const currentTenantContext = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.currentTenantContext,
);
export const currentTenant = createSelector(selectUserDataFeature, (state: UserPreferences) => state.currentTenant);
export const joinOrgSuccess = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.showJoinSuccessInlineMessage,
);
export const suggestedTenant = createSelector(selectUserDataFeature, (state: UserPreferences) => state.suggestedTenant);

export const noSuggestedTenant = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.noSuggestedTenant,
);
export const accessibleTenants = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.accessibleTenants,
);
export const effectiveRoles = createSelector(selectUserDataFeature, (state: UserPreferences) => state.effectiveRoles);
export const notifications = createSelector(selectUserDataFeature, (state: UserPreferences) => state.notifications);
export const notificationCounts = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.notificationCounts,
);
export const notificationDeleted = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.notificationDeleted,
);
export const notificationMarkedRead = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.notificationMarkedRead,
);
export const selectServicesVisibility = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.servicesVisibility,
);
export const selectServices = createSelector(selectUserDataFeature, (state: UserPreferences) => state.services);
export const selectTenantUtilityTokenBalance = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.tenantUtilityTokens?.tokenBalance,
);
export const selectTenantUtilityTokenEntitlements = createSelector(selectUserDataFeature, (state: UserPreferences) =>
  state.tenantUtilityTokens?.tokenEntitlements.concat(state.tenantUtilityTokens?.disabledTokenEntitlements),
);
export const selectPendingServices = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.currentTenant?.pendingServices,
);
export const selectCustomProvisioningState = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state?.currentCustomProvisioningData,
);
export const selectPreferences = createSelector(selectUserDataFeature, (state: UserPreferences) => state.preferences);
export const selectExitFromProvisioning = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.exitFromProvisioning,
);

export const selectFavoriteApps = createSelector(
  selectUserDataFeature,
  (state: UserPreferences) => state.preferences?.favoriteApps || {},
);
