import { Action, createReducer, on } from '@ngrx/store';
import { setTrialBundles, setTrialReservationInfo } from './trials.actions';
import { Trials } from '../lemans-app.model';

export const trialsInitialState: Trials = {
  bundles: [
    {
      id: '',
      name: '',
      description: '',
      credits: '',
      products: [],
    },
  ],
};
const setTrialsData = createReducer(
  trialsInitialState,
  on(setTrialReservationInfo, (state: Trials, { payload }) => ({
    ...state,
    reservation: payload,
  })),
  on(setTrialBundles, (state: Trials, { payload }) => ({
    ...state,
    bundles: payload,
  })),
);

export function trialsReducer(state: Trials, action: Action): Trials {
  return setTrialsData(state, action);
}
