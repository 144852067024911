/* eslint-disable sort-keys */
import { HttpHeaders } from '@angular/common/http';
import { CustomErrorMessage } from '@core/common-constants';
import { IDomainMessage } from '@ra-state';
import { Observable } from 'rxjs';

type CommandRequestOptions = {
  body: any;
  headers: HttpHeaders;
  observe: 'response';
};
type MethodType = 'POST' | 'DELETE' | 'PUT';

export class CommandRequest {
  get Method(): MethodType {
    return this.method;
  }

  get ApiEndpoint(): string {
    return this.apiEndPoint;
  }

  get RequestOptions(): CommandRequestOptions {
    return {
      body: this.body,
      headers: this.headers,
      observe: 'response',
    };
  }

  get TimeOutInMillies(): number {
    return this.timeoutMillis;
  }

  get errorHandler$(): (error: unknown) => Observable<any> {
    return this.errorHandler;
  }

  get ErrorMessage(): string {
    return this.errorMsg;
  }

  get getCustomErrorMsg(): CustomErrorMessage[] {
    return this.errorMsgDictionary;
  }

  public skipWaitingOnResponse(responseStatus): boolean {
    return !this.WaitOnSuccessResponsePredicate(responseStatus);
  }

  private apiEndPoint: string;
  private timeoutMillis: number;
  private event: EventPredicate | DomainEventName | DomainEvents;
  private headers: HttpHeaders;
  private body: any;
  private method: 'POST' | 'DELETE' | 'PUT';
  private errorHandler: (error: unknown) => Observable<any>;
  private errorMsgDictionary: CustomErrorMessage[];
  private errorMsg: string;
  private WaitOnSuccessResponsePredicate: WaitOnSuccessResponsePredicate;
  constructor(apiEndPoint, method, event, body, headers, timeoutMillis, waitOnSuccessResponsePredicate) {
    this.apiEndPoint = apiEndPoint;
    this.method = method;
    this.event = event;
    this.body = body;
    this.headers = headers;
    this.timeoutMillis = timeoutMillis;
    this.WaitOnSuccessResponsePredicate = waitOnSuccessResponsePredicate;
  }

  private static waitOnCreatedResponse(status: number): boolean {
    return status === 201;
  }

  static create(
    apiEndPoint: string,
    method: 'POST' | 'DELETE' | 'PUT',
    domainEvent: EventPredicate | string,
    options: IDomainUpdateRequestBuilderOptions = {} as IDomainUpdateRequestBuilderOptions,
  ): CommandRequest {
    const defaultOptions = {
      WaitOnSuccessResponsePredicate: CommandRequest.waitOnCreatedResponse,
      body: {},
      headers: new HttpHeaders(),
      timeoutSeconds: 60,
    };
    const targetOptions = Object.assign(defaultOptions, options);
    const timeoutMillis = targetOptions.timeoutSeconds * 1000;
    const waitOnSuccessResponsePredicate = targetOptions.WaitOnSuccessResponsePredicate;

    return new CommandRequest(
      apiEndPoint,
      method,
      domainEvent,
      targetOptions.body,
      targetOptions.headers,
      timeoutMillis,
      waitOnSuccessResponsePredicate,
    );
  }

  // TO DO: define retuning type
  withBody(body): this {
    this.body = body;
    return this;
  }

  withTenantHeader(tenantId: string): CommandRequest {
    this.headers = this.headers.set('tenantid', tenantId);
    return this;
  }

  withCustomTimer(customtimer: string): CommandRequest {
    this.headers = this.headers.set('customtimer', customtimer);
    return this;
  }

  withIdempotencyKey(idempotencyKey: string): CommandRequest {
    this.headers = this.headers.set('idempotencyKey', idempotencyKey);
    return this;
  }

  withTimeout(timeoutInSeconds): CommandRequest {
    this.timeoutMillis = timeoutInSeconds * 1000;
    return this;
  }

  withWaitOn201Created(): CommandRequest {
    this.WaitOnSuccessResponsePredicate = CommandRequest.waitOnCreatedResponse;
    return this;
  }

  withWaitOn200Ok(): CommandRequest {
    this.WaitOnSuccessResponsePredicate = (responseStatus: number): boolean => responseStatus === 200;
    return this;
  }

  withWaitOn202Accepted(): CommandRequest {
    this.WaitOnSuccessResponsePredicate = (responseStatus: number): boolean => responseStatus === 202;
    return this;
  }

  withErrorHandler(errorHandler: (error: unknown) => Observable<any>): CommandRequest {
    this.errorHandler = errorHandler;
    return this;
  }

  withCustomErrorMessage(errorMessages: CustomErrorMessage[]): CommandRequest {
    this.errorMsgDictionary = errorMessages;
    return this;
  }

  // TO DO: define retuning type
  waitOnResponse(fn: (status: number) => boolean): any {
    this.WaitOnSuccessResponsePredicate = fn;
    return this;
  }

  public eventPredicateSatisfied = (domainMessage: IDomainMessage): boolean => {
    if (!domainMessage) {
      return false;
    }

    if (typeof this.event === 'string') {
      return domainMessage.type === this.event;
    } else if (typeof this.event === 'function') {
      return this.event(domainMessage);
    }
    return false;
  };

  public notEventPredicateSatisfied = (domainMessage: IDomainMessage): boolean => {
    return !this.eventPredicateSatisfied(domainMessage);
  };
}

export type WaitOnSuccessResponsePredicate = (status: number) => boolean;
export type EventPredicate = (domainMessage: IDomainMessage) => boolean;

export interface IDomainUpdateRequestBuilderOptions {
  body: any;
  headers: HttpHeaders;
  timeoutSeconds: number;
  WaitOnSuccessResponsePredicate: WaitOnSuccessResponsePredicate;
}

export enum TenantEvent {
  TenantCreated = 'TenantCreated',
  TenantUpdated = 'TenantUpdated',
  TenantNameUpdated = 'TenantNameUpdated',
  TenantDeleted = 'TenantDeleted',
  ServiceAddedV2 = 'ServiceAddedV2',
  ServiceAdditionCancelled = 'ServiceAdditionCancelled',
  ResourceAccessGranted = 'ResourceAccessGranted',
  ResourceAccessRevoked = 'ResourceAccessRevoked',
  InviteCodeGenerated = 'InviteCodeGenerated',
  EntitlementAddedV2 = 'EntitlementAddedV2',
  EntitlementConsumedV2 = 'EntitlementConsumedV2',
  EntitlementDisabled = 'EntitlementDisabled',
  ServiceVisibilityUpdated = 'ServiceVisibilityUpdated',
  TokenEntitlementAllocated = 'TokenEntitlementAllocated',
  ServiceAdditionStarted = 'ServiceAdditionStarted',
  EntitlementAdditionStarted = 'EntitlementAdditionStarted',
}
export enum UserEvent {
  UserEulaAccepted = 'UserEulaAccepted',
  UserRoleAssigned = 'UserRoleAssigned',
  UserRoleRevoked = 'UserRoleRevoked',
}
export enum InvitationEvent {
  InvitationCreated = 'InvitationCreated',
  InvitationReissuedV2 = 'InvitationReissuedV2',
  InvitationFinalized = 'InvitationFinalized',
  UserRoleUpdateErroredV2 = 'UserRoleUpdateErroredV2',
}
export enum EntitlementEvent {
  EntitlementConsumptionReversedV2 = 'EntitlementConsumptionReversedV2',
  EntitlementDeallocated = 'EntitlementDeallocated',
  EntitlementAddedV2 = 'EntitlementAddedV2',
}
export enum AccessRequestEvent {
  AccessRequestCreated = 'AccessRequestCreated',
  Approved = 'Approved',
  Deleted = 'Deleted',
}

export enum TrialEvent {
  TrialRedeemed = 'TrialRedeemed',
  TrialAllocated = 'TrialAllocated',
  TrialAllocationFailed = 'TrialAllocationFailed',
}

export type DomainEvents =
  | TenantEvent
  | UserEvent
  | InvitationEvent
  | EntitlementEvent
  | AccessRequestEvent
  | TrialEvent;
export type DomainEventName =
  | keyof typeof InvitationEvent
  | keyof typeof UserEvent
  | keyof typeof TenantEvent
  | keyof typeof EntitlementEvent
  | keyof typeof AccessRequestEvent;
// TODO: Move this const to models folder
//export const DomainEvents = { };
