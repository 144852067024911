import {
  AgFilterContext,
  AgFilterModel,
  AgGridEntitlementColumnsFormat,
  BadgeInfo,
  Entitlement,
  EntitlementBadge,
  EntitlementStatus,
  EntitlmentService,
  EntitylementTypes,
  FilterContext,
  FilterModel,
  FilterType,
  ITenantDetail,
  InvitationsStatus,
  ManageResourceAccess,
  RASymbols,
  RemoveResourceAccess,
  ResourceTypeString,
  TOKEN_BALANCE_FRACTION_DIGITS,
  LinkStatus,
  TrialStatus,
} from '@ra-state';
import { DialogService, IDialogConfig } from '@ra-web-tech-ui-toolkit/components';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { Observable, from } from 'rxjs';
import * as _ from 'lodash';

export function getBadgeInfo(value: string): BadgeInfo {
  switch (value) {
    case EntitylementTypes.addOns:
      return { color: 'lightBlue', text: 'Add-Ons' };
    case EntitylementTypes.trial:
      return { color: 'darkBlue', text: EntitylementTypes.trial };
    case EntitylementTypes.platform:
      return { color: 'blue', text: EntitylementTypes.platform };
    case EntitylementTypes.additive:
      return { color: 'purple', text: EntitylementTypes.additive };
    case EntitylementTypes.utilityToken:
      return { color: 'purple', text: 'Universal Credits' };
    default:
      return { color: 'none', text: RASymbols.MINUS };
  }
}

export function mapUTCDateTimeToLocal(dateTime: string, options?: string, languageCode?: string): string {
  if (dateIsValid(dateTime)) {
    let defaultOption: DateTimeFormatOptions;
    switch (options) {
      case 'date':
        defaultOption = DateTime.DATE_MED;
        break;
      case 'dateTime':
        defaultOption = DateTime.DATETIME_MED;
        break;
      case 'short':
        defaultOption = DateTime.DATE_SHORT;
        break;
      default:
        defaultOption = DateTime.DATE_FULL;
    }
    const selectedLanguage = languageCode || 'en-US';
    return DateTime.fromISO(new Date(dateTime).toISOString()).setLocale(selectedLanguage).toLocaleString(defaultOption);
  }
  return '';
}

export function formatEntitlementCopyColumnContent(data: AgGridEntitlementColumnsFormat): any {
  return `${data.header} ${data.subheader}`;
}

export function mapServiceData(params: Entitlement): any {
  // TODO: this func is extremely fishy - see this.allApps below - it's coming from the default this from
  const serviceKind = params?.serviceKind;
  const appName = serviceKind ? getAppNameOrDefault(this.allApps, serviceKind) : '';
  return {
    header: this.catalogInfos.get(params?.catalogNumber)?.name || params?.catalogNumber,
    subheader: appName + 'Catalog: ' + params?.catalogNumber,
  };
}

function getAppNameOrDefault(allApps, serviceKind): any {
  return (allApps.get(serviceKind)?.appName || serviceKind) + ' | ';
}

export function dateIsValid(date): any {
  return !Number.isNaN(new Date(date).getTime());
}

export function formatBadgeCopyColumnContent(badge: string): any {
  return EntitlementBadge[badge as unknown as keyof typeof EntitlementBadge];
}

export function openDialogWrapper$(dialogSvc: DialogService, config: IDialogConfig): Observable<any> {
  const dialog = dialogSvc.openDialog(config);
  return dialog.componentInstance.onClick;
}

export function getDisplayResourceType(v: ResourceTypeString): string {
  if (v === 'Tenant') {
    return 'Organization';
  }
  return v;
}

export function agDateComparator(filterDate: Date, cellValue: string | null): any {
  if (cellValue === null) {
    return -1;
  }
  const cellDate = new Date(cellValue);
  cellDate.setHours(0, 0, 0, 0); // Setting hours as 0 for exact comparision - As filterDate is filterLocalDateAtMidnight (without hours)
  if (filterDate.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate.getTime() <= filterDate.getTime()) {
    return -1;
  }
  if (cellDate.getTime() >= filterDate.getTime()) {
    return 1;
  }
  return 0;
}

export function toFilterModel(agFilterModel: AgFilterModel): FilterModel {
  const filterModel: FilterModel = {};
  Object.keys(agFilterModel).forEach((key) => {
    const agFilterContext: AgFilterContext = agFilterModel[key];
    const filterContext: FilterContext = {
      type: agFilterModel[key].type,
      filterType: agFilterModel[key].filterType,
    };

    switch (agFilterContext.filterType) {
      case 'number':
      case 'text':
        filterContext.filter = agFilterModel[key].filter;
        filterContext.filterTo = agFilterModel[key].filterTo;
        break;

      case 'set':
        filterContext.values = agFilterModel[key].values?.map((value) => {
          if (value === null) {
            return '';
          }
          return value;
        });
        break;

      case 'date':
        filterContext.dateFrom = getDateWithMaxOrMinDayTime(`${agFilterContext?.dateFrom}`, agFilterContext?.type);
        filterContext.dateTo = agFilterContext.dateTo
          ? getDateWithMaxDayTime(`${agFilterContext?.dateTo}`)
          : new Date();
        break;

      default:
        break;
    }
    filterModel[key] = filterContext;
  });
  return filterModel;
}

export function getDateWithMaxOrMinDayTime(value: string, filterType?: FilterType): Date {
  switch (filterType) {
    case 'lessThanOrEqual':
      return getDateWithMaxDayTime(value);
    case 'greaterThanOrEqual':
      return getDateWithMinDayTime(value);
    case 'inRange':
      return getDateWithMinDayTime(value);
    default:
      const datePart = value.split(' ')[0];
      return new Date(datePart);
  }
}

export function getDateWithMaxDayTime(value: string): Date {
  const datePart = value.split(' ')[0];
  return new Date(`${datePart}T23:59:59`);
}

export function getDateWithMinDayTime(value: string): Date {
  const datePart = value.split(' ')[0];
  return new Date(`${datePart}T00:00:01`);
}

export class Awaiter {
  promises: Map<Promise<boolean>, any> = new Map();
  createPromise(): Promise<boolean> {
    let deferred: any;
    const p = new Promise<boolean>((resolve, reject) => (deferred = { resolve, reject }));
    this.promises.set(p, deferred);
    return p;
  }
  resolve(p: Promise<boolean>): void {
    this.promises.get(p).resolve();
  }
  reject(p: Promise<boolean>): void {
    this.promises.get(p).reject();
  }
  whenAll(): Promise<any> {
    const promises = [...this.promises.keys()];
    if (promises.length === 0) {
      return new Promise<void>((resolve, _reject) => resolve());
    }
    return Promise.all(promises);
  }

  whenAnySettles(): Promise<any> {
    const promises = [...this.promises.keys()];
    if (promises.length === 0) {
      return new Promise<void>((resolve, _reject) => resolve());
    }
    return Promise.race(promises);
  }
  whenAllSettled(): Promise<any> {
    return Promise.allSettled([...this.promises.keys()]);
  }
  whenFirstSuccess(): Promise<any> {
    return Promise.any([...this.promises.keys()]);
  }
  any = this.whenFirstSuccess;
  race = this.whenAnySettles;
  allSettled = this.whenAllSettled;
  get all$(): Observable<any> {
    return from(this.whenAll());
  }
  get any$(): Observable<any> {
    return from(this.any());
  }
}

export function openURL(url: string): void {
  window.open(url, '_blank');
}

export function roundToThreeDecimals(value: number): number {
  const roundingFactor = Math.pow(10, TOKEN_BALANCE_FRACTION_DIGITS);
  return Math.ceil(value * roundingFactor) / roundingFactor;
}

export function getEntitlementStatus(status: EntitlementStatus): string {
  return status === EntitlementStatus.COMPLETED ? 'Expired' : status;
}

export function getLinkStatus(status: LinkStatus): string {
  return status === LinkStatus.EXPIRED ? 'Expired' : status;
}

export function getTrialtatus(status: TrialStatus): string {
  return status === TrialStatus.PROVISIONING ? 'Needs action' : status;
}

export function modifyFilterValuesforCredit(filterModel: FilterModel, colName: string): FilterModel {
  const filterModelClone = _.cloneDeep(filterModel);
  if (filterModelClone[colName]) {
    filterModelClone['hasCredits'] = filterModelClone[colName];
    delete filterModelClone[colName];
  }
  return filterModelClone;
}

export function getInvitationStatus(status: InvitationsStatus): string {
  return status === InvitationsStatus.ACTIVE ? 'Sent' : status;
}

export function checkIfTrialEntitlement(entitlement: Entitlement): boolean {
  if (entitlement.attributes?.campaignId || entitlement.catalogNumber === 'TRIAL-CAMPAIGN-CREDITS') {
    return true;
  }
  return false;
}

export function getServiceEntitlements(tenantDetails: ITenantDetail): Entitlement[] {
  const mapEntitlements = (services: EntitlmentService[]): Entitlement[] => {
    return (
      services
        ?.flatMap((service) => {
          return service.entitlements.map((entitlement) => ({
            ...entitlement,
            serviceKind: service.kind,
            isTrialEntitlement: checkIfTrialEntitlement(entitlement),
          }));
        })
        ?.filter((entitlement) => !entitlement.isSystemGenerated) || []
    );
  };

  const pendingServicesEntitlements = (tenantDetails.pendingServices || [])
    .flatMap((pendingService) => {
      return pendingService.entitlement
        ? {
            ...pendingService.entitlement,
            serviceKind: pendingService.kind,
            isTrialEntitlement: checkIfTrialEntitlement(pendingService.entitlement),
          }
        : [];
    })
    .filter((entitlement) => !entitlement.isSystemGenerated);

  const provisionedServicesEntitlements = mapEntitlements(tenantDetails.services || []);
  const disabledServiceEntitlements = mapEntitlements(tenantDetails.disabledServices || []);

  return [...provisionedServicesEntitlements, ...pendingServicesEntitlements, ...disabledServiceEntitlements];
}

export function isRemoveAccessResource(row: ManageResourceAccess): boolean {
  return (row as RemoveResourceAccess)?.availableToDelete !== undefined;
}
