import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewTitleComponent } from './view-title/view-title.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FeedbackComponent } from './feedback-modal/feedback.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { AgTwoButtonRenderComponent } from './ag-renderers/ag-two-buttons-render/ag-two-buttons-render.component';
import { ErrorComponent } from './error/error/error.component';
import { RouterModule } from '@angular/router';
import { ResourceRolesMapperComponent } from './resource-roles-mapper/resource-roles-mapper.component';
import { DropdownformComponent } from './dropdownform/dropdownform.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { ContentModalComponent } from './content-modal/content-modal.component';
import { AgDeleteIconRenderComponent } from '@shared/ag-renderers/ag-delete-icon-render/ag-delete-icon-render.component';
import { MatIconModule } from '@angular/material/icon';
import {
  RaUiButtonModule,
  RaUiMultilineInputModule,
  RaUiTooltipModule,
  RaUiErrorMessageModule,
  RaUiEmptyStateModule,
  RaUiChipListModule,
  RaUiInputModule,
  RaUiBadgeModule,
} from '@ra-web-tech-ui-toolkit/components';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AgBadgeRenderComponent } from './ag-renderers/ag-badge-render/ag-badge-render.component';
import { AgCustomNoRowsOverlayComponent } from '@agrenderers/ag-custom-no-rows-overlay/ag-custom-no-rows-overlay.component';
import { TranslateModule } from '@ngx-translate/core';
import { EmailInputComponent } from './email-input/email-input.component';
import { BuildInformationComponent } from './build-information/build-information.component';
import { AgTimeFormatComponent } from './ag-renderers/ag-time-format.component';

const COMPONENTS = [
  ViewTitleComponent,
  BuildInformationComponent,
  FeedbackComponent,
  AgTwoButtonRenderComponent,
  AgDeleteIconRenderComponent,
  ResourceRolesMapperComponent,
  DropdownformComponent,
  ContentModalComponent,
  ErrorComponent,
  SafeHtmlPipe,
  AgBadgeRenderComponent,
  AgCustomNoRowsOverlayComponent,
  EmailInputComponent,
  AgTimeFormatComponent,
];
@NgModule({
  declarations: [COMPONENTS],
  exports: [COMPONENTS, TranslateModule, RaUiButtonModule],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    RouterModule,
    AgGridModule,
    MatIconModule,
    RaUiButtonModule,
    RaUiMultilineInputModule,
    RaUiTooltipModule,
    RaUiErrorMessageModule,
    RaUiEmptyStateModule,
    TranslateModule,
    RaUiChipListModule,
    RaUiInputModule,
    RaUiBadgeModule,
  ],
})
export class SharedModule {}
